import {
  UnwellReport,
  UnwellReportResponseData,
} from '~/context/global/reports/types';
import { httpClient, Response } from '~/services/http';
import { snakecase } from '~/common/casing';
import routes from '../routes';

export type UnwellReportRequestBody = UnwellReport;

export const submitUnwellReport = (
  unwellReport: UnwellReportRequestBody,
  recaptchaToken: string
): Promise<Response<UnwellReportResponseData>> => {
  return httpClient.post<undefined>(
    routes.unwellReport,
    snakecase(unwellReport),
    {
      headers: { recaptchaToken },
    }
  );
};
