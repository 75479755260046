import React from 'react';
import SEO from '~/components/seo';
import Hero from '~/components/Hero/index.tsx';
import { isBrowser } from '~/common/generalUtils';
import { PathSelectionForm } from '~/components/forms/PathSelection/index.tsx';
import 'mapbox-gl/dist/mapbox-gl.css';

const IndexPage = props => {
  const scrollToMap =
    props.location && props.location.state && props.location.state.goToMap;

  React.useEffect(() => {
    if (!isBrowser || !scrollToMap) {
      return;
    }

    const timeout = setTimeout(() => {
      const header = window.document.querySelector('header.MuiAppBar-root');
      const target = window.document.getElementById('goToMapTarget');

      if (!target) {
        return;
      }

      // Scroll page to map section
      window.scrollTo({
        behavior: 'smooth',
        top: target.offsetTop - header.offsetHeight,
      });
    }, 0);

    return function cleanup() {
      clearTimeout(timeout);
    };
  }, [scrollToMap]);

  return (
    <>
      <SEO pageContext={props.pageContext} title="Landing.pageTitle" />
      <Hero includeBrands isLanding>
        <PathSelectionForm />
      </Hero>
    </>
  );
};

export default IndexPage;
