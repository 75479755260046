import { useGlobalState, useGlobalDispatch } from '~/context/global';
import { UserDemographics } from '~/context/global/user';
import {
  isVaccinatedForFlu,
  isVaccinatedForCovid,
} from '~/common/vaccinations';

interface UseUserDemographics {
  skipDemographicsForm: boolean;
  demographics: {
    hasGender: boolean;
    hasRace: boolean;
    hasEthnicity: boolean;
    hasDateOfBirth: boolean;
    hasZipCode: boolean;
    isFluVaccinated: boolean;
    isFullyCovidVaccinated: boolean;
  };
  hasUserDemographics: boolean;
  saveUserDemographicsToStore: (formValues: UserDemographics) => void;
}

export const useUserDemographics = (): UseUserDemographics => {
  const { account } = useGlobalState().user;
  const dispatch = useGlobalDispatch();

  const hasGender = !!account.gender || false;
  const hasRace = !!account.userRace?.race?.id || false;
  const hasEthnicity = !!account.userEthnicity?.ethnicity.id || false;
  const hasDateOfBirth = !!account.dateOfBirth || false;
  const hasZipCode = !!account.zipcode || false;
  const isFluVaccinated = isVaccinatedForFlu(account?.vaccinations);
  const isFullyCovidVaccinated = isVaccinatedForCovid(account?.vaccinations);

  const skipDemographicsForm =
    hasGender &&
    hasRace &&
    hasDateOfBirth &&
    hasZipCode &&
    isFluVaccinated &&
    isFullyCovidVaccinated;

  const demographics = {
    hasGender,
    hasRace,
    hasEthnicity,
    hasDateOfBirth,
    hasZipCode,
    isFluVaccinated,
    isFullyCovidVaccinated,
  };

  const hasUserDemographics =
    hasGender && hasRace && hasDateOfBirth && hasZipCode;

  const saveUserDemographicsToStore = (formValues: UserDemographics) => {
    if (Object.values(formValues).length) {
      dispatch({
        type: 'SET_USER_DEMOGRAPHIC_DATA',
        payload: { ...formValues },
      });
      dispatch({
        type: 'SET_USER_VACCINATION_DATA',
        payload: { ...formValues },
      });
    }
  };

  return {
    skipDemographicsForm,
    hasUserDemographics,
    demographics,
    saveUserDemographicsToStore,
  };
};
