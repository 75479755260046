import { useState, useContext } from 'react';
import { CountryContext } from '~/context/i18n';
import { useGlobalState } from '~/context/global';
import { UserDemographics } from '~/context/global/user';
import { submitWellReport, WellReportRequestBody } from '~/requests/reports';
import { WellReportResponseData } from '~/context/global/reports/types';
import { VaccinationValue } from '~/common/vaccinations';

interface UseWellReportSubmission {
  loading: boolean;
  submitReport: (
    wellReport: UserDemographics[],
    recaptchaToken: string
  ) => Promise<WellReportResponseData>;
}

export const useWellReportSubmission = (): UseWellReportSubmission => {
  const [loading, setLoading] = useState(false);
  const { country } = useContext(CountryContext);
  const userAccount = useGlobalState().user?.account;

  const makeRequestBody = (
    wellReports: UserDemographics[]
  ): WellReportRequestBody[] => {
    return wellReports.map(wellReport => {
      return {
        email: userAccount?.email,
        gender: wellReport?.gender ?? userAccount?.gender,
        race_id: wellReport?.raceId ?? userAccount?.userRace?.race?.id,
        ethnicity_id:
          wellReport?.ethnicityId ?? userAccount?.userEthnicity?.ethnicity.id,
        user_submitted_race:
          wellReport?.userSubmittedRace ??
          userAccount?.userRace?.userSubmittedRace,
        birthdate: wellReport?.birthdate ?? userAccount?.dateOfBirth,
        zipcode: wellReport?.zipcode ?? userAccount?.zipcode,
        country_code: userAccount?.countryCode ?? country.code.toUpperCase(),
        flu_season:
          wellReport?.fluSeason ?? userAccount?.vaccinations?.fluSeason,
        covid_season:
          wellReport?.covidSeason ?? userAccount?.vaccinations?.covidSeason,
        received_flu_vaccine:
          wellReport?.receivedFluVaccine ??
          userAccount?.vaccinations?.flu ??
          VaccinationValue.UNKNOWN,
        received_covid_vaccine:
          wellReport?.receivedCovidVaccine ??
          userAccount?.vaccinations?.covid ??
          VaccinationValue.UNKNOWN,
      };
    });
  };

  const submitReport = async (
    wellReport: UserDemographics[],
    recaptchaToken: string
  ): Promise<WellReportResponseData> => {
    setLoading(true);
    try {
      const wellReportRequestBody = makeRequestBody(wellReport);
      const { data } = await submitWellReport(
        wellReportRequestBody,
        recaptchaToken
      );
      return data;
    } finally {
      setLoading(false);
    }
  };

  return { loading, submitReport };
};
