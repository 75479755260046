import { Vaccinations, Vaccine } from '~/context/global/user/account';
import { isPast, isToday } from 'date-fns';

export enum VaccinationValue {
  YES = 'YES',
  NO_BUT_WILL = 'NO_BUT_WILL',
  NO_AND_WONT = 'NO_AND_WONT',
  UNKNOWN = 'UNKNOWN',
}

export const isVaccinatedForCovid = (vaccinations: Vaccinations): boolean => {
  const currentCovidSeason = getCovidVaccineYear();
  return (
    vaccinations?.covid === VaccinationValue.YES &&
    vaccinations?.covidSeason === currentCovidSeason
  );
};
export const isVaccinatedForFlu = (vaccinations: Vaccinations): boolean => {
  const currentFluSeason = getCurrentFluSeason();
  return (
    vaccinations?.flu === VaccinationValue.YES &&
    vaccinations?.fluSeason === currentFluSeason
  );
};

export const isVaccinated = (
  vaccine: Vaccine,
  vaccinations: Vaccinations
): boolean => {
  switch (vaccine) {
    case Vaccine.COVID:
      return isVaccinatedForCovid(vaccinations);
    case Vaccine.FLU:
      return isVaccinatedForFlu(vaccinations);
    default:
      return false;
  }
};

/**
 * It returns the current year if the current date is July 1st or after, and the previous year otherwise.
 * @returns the cutoff year for the flu vaccine
 */

export const getFluVaccineCutoffYear = (): number => {
  const julyFirst = new Date();
  julyFirst.setMonth(6, 1);

  return isToday(julyFirst) || isPast(julyFirst)
    ? julyFirst.getFullYear()
    : julyFirst.getFullYear() - 1;
};

/**
 * Utility function to get the current flu season since a new one starts every 1st of July
 * @returns {string} e.g. '2024/2025'
 */

export const getCurrentFluSeason = (): string => {
  const today = new Date();
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  const lastYear = currentYear - 1;
  const cutOffDate = new Date(currentYear, 6, 1); // July 1st
  return today < cutOffDate
    ? lastYear + '/' + currentYear
    : currentYear + '/' + nextYear;
};

export const getCovidVaccineYear = (): string => {
  const today = new Date();
  const currentYear = new Date().getFullYear();
  const lastYear = currentYear - 1;
  const cutOffDate = new Date(currentYear, 8, 1); // September 1st
  return today < cutOffDate ? `${lastYear}` : `${currentYear}`;
};

export interface VaccinationsForm {
  receivedFluVaccine: VaccinationValue;
  receivedCovidVaccine: VaccinationValue;
}

export const getVaccineForField = (
  vaccinationField: keyof VaccinationsForm
): keyof Vaccinations => {
  switch (vaccinationField) {
    case 'receivedCovidVaccine':
      return 'covid';
    case 'receivedFluVaccine':
      return 'flu';
    default:
      break;
  }
};
