import React from 'react';
import * as Styled from './styles';
import { Grid, Container } from '@material-ui/core';
import { ExternalBrand } from '~/components/BaseElements';

interface HeroProps {
  isSubPage?: boolean;
  isLanding?: boolean;
  fullWidth?: boolean;
}

const SubPage: React.FC<HeroProps> = ({ children }) => {
  return (
    <Styled.Hero data-testid="hero-sub-page">
      <Styled.Grid container alignItems="center">
        <Grid item xs={12}>
          <Container>
            <Styled.Heading>{children}</Styled.Heading>
          </Container>
        </Grid>
      </Styled.Grid>
    </Styled.Hero>
  );
};

const RegularPage: React.FC<HeroProps> = ({
  children,
  isLanding = false,
  fullWidth = false,
}) => {
  return (
    <Styled.RegularPageContainer data-testid="hero-regular-page">
      <Container>
        <Styled.RegularPageContentArea
          fullWidth={fullWidth}
          isLanding={isLanding}
        >
          {children}
        </Styled.RegularPageContentArea>
      </Container>
      <Styled.PartnersContentArea>
        <ExternalBrand />
      </Styled.PartnersContentArea>
    </Styled.RegularPageContainer>
  );
};

const Hero: React.FC<HeroProps> = props => {
  return props.isSubPage ? <SubPage {...props} /> : <RegularPage {...props} />;
};

export default Hero;
