import routes from '../routes';
import { httpClient, Response } from '~/services/http';

export interface PairSubmissionToAccountResponse {
  errormessage?: string;
}

export const pairPreviousSubmissionsToAccount = async (
  submissionIds: number[]
): Promise<Response<PairSubmissionToAccountResponse>> => {
  return httpClient.patch<PairSubmissionToAccountResponse>(
    routes.pairSubmissionsToAccount,
    { submissionIds }
  );
};
