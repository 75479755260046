import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from '~/store';
import Grid from '@material-ui/core/Grid';
import { useGlobalState } from '~/context/global';
import { useTranslation, Trans } from 'react-i18next';
import { storeSubmissionId } from '~/common/storageUtils';
import { ThumbDown, ThumbUp } from '@material-ui/icons';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { useNavigate } from '~/state/hooks/useNavigate';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSubmissionStatsData } from '~/state/hooks/useSubmissionStats';
import { useUserDemographics } from '~/state/hooks/useUserDemographics';
import { useHouseholdMembers } from '~/state/hooks/useHouseholdMembers';
import { useWellReportSubmission } from '~/state/hooks/useWellReportSubmission';
import * as Styled from './styles';

export const PathSelectionForm: React.FC = () => {
  const { startNumber, aggregateSubmissions, user } = useGlobalState();
  const { t } = useTranslation();
  const { navigate } = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { submitReport } = useWellReportSubmission();
  const { skipDemographicsForm } = useUserDemographics();
  const { loggedIn } = useSelector(state => state.userAuth);
  const { hasActiveHouseholdMembers } = useHouseholdMembers();
  useSubmissionStatsData();

  const isFetchingUser = loggedIn && isEmpty(user.account);
  const isHouseholdReport = hasActiveHouseholdMembers();

  const handleUnwellFlow = () => navigate(`unwell/symptoms`);

  const handleWellFlow = async () => {
    if (!skipDemographicsForm) {
      return navigate('well', {
        state: { isWellForm: true },
      });
    } else {
      const userDemographics = {
        gender: user.account.gender,
        raceId: user.account.userRace?.race?.id,
        userSubmittedRace: user.account.userRace?.userSubmittedRace,
        zipcode: user.account.zipcode,
        birthdate: user.account.dateOfBirth,
      };

      if (!executeRecaptcha) {
        return showSnackbar(t('ErrorHandling.recaptcha'), 'error');
      }

      try {
        const recaptchaToken = await executeRecaptcha('well');
        const { userSubmissionId } = await submitReport(
          [userDemographics],
          recaptchaToken
        );
        !loggedIn && storeSubmissionId(userSubmissionId);
        navigate('bonus-questions', {
          state: { wellSubmissionId: userSubmissionId },
        });
      } catch (error) {
        const errorCode = error?.response?.data?.errorCode ?? 'ServerError';
        showSnackbar(t(`ErrorHandling.submissionErrors.${errorCode}`), 'error');
      }
    }
  };

  const handleHouseholdFlow = () => navigate('household-report');

  const renderHealthStatusQuestion = (): JSX.Element => {
    if (isFetchingUser) {
      return (
        <>
          <Styled.Skeleton height={35} />
          <Styled.Skeleton height={35} />
          <Styled.Skeleton height={35} />
        </>
      );
    }

    if (isHouseholdReport) {
      return <Trans i18nKey="PathSelection.householdQuestion"></Trans>;
    }

    return <Trans i18nKey="PathSelection.question"></Trans>;
  };

  const renderHealthReportButtons = (): JSX.Element => {
    if (isFetchingUser) {
      return <Styled.Skeleton height={50} />;
    }

    if (isHouseholdReport) {
      return (
        <Styled.HouseholdReportButton
          onClick={handleHouseholdFlow}
          data-test="household_health_report_button"
        >
          {t('PathSelection.buttonHousehold')}
        </Styled.HouseholdReportButton>
      );
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <Styled.FeelingWellButton
            onClick={handleWellFlow}
            startIcon={<ThumbUp />}
            disabled={!executeRecaptcha}
            data-cy="well_button"
          >
            {t('PathSelection.buttonGood')}
          </Styled.FeelingWellButton>
        </Grid>

        <Grid item xs={12} sm={6} md={12} lg={6}>
          <Styled.FeelingUnwellButton
            onClick={handleUnwellFlow}
            startIcon={<ThumbDown />}
            data-test="unwell_btn"
          >
            {t('PathSelection.buttonBad')}
          </Styled.FeelingUnwellButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Styled.InfoText>
        <Trans i18nKey="PathSelection.helpTrack">
          <Styled.Counter start={startNumber} end={aggregateSubmissions} />
        </Trans>
      </Styled.InfoText>

      <Styled.MainQuestion>{renderHealthStatusQuestion()}</Styled.MainQuestion>

      {renderHealthReportButtons()}
    </>
  );
};
