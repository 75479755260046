import styled from 'styled-components';
import hero from '~/assets/images/hero.jpg';
import breakpoint from '~/common/breakpoint';
import UnstyledGrid from '@material-ui/core/Grid';
import heroMobile from '~/assets/images/hero@mobile.jpg';
import { fade, alpha } from '@material-ui/core/styles/colorManipulator';
import { Heading as UnstyledHeading } from '~/components/BaseElements';

export const RegularPageContainer = styled.section`
  position: relative;
  background-size: cover;
  background-position: center;
  background-image: url(${heroMobile});

  ${breakpoint.md`
    background-image: url(${hero});
  `};
`;

export const RegularPageContentArea = styled.div<{
  fullWidth: boolean;
  isLanding: boolean;
}>`
  background: ${props => fade(props.theme.palette.common.white, 0.9)};
  position: relative;
  z-index: 1;
  padding: ${props => `
    ${props.theme.spacing(props.isLanding ? 16 : 12)}px
    ${props.theme.spacing(2)}px
    ${props.theme.spacing(27)}px
  `};

  ${breakpoint.xs`
    text-align: center;
  `}

  ${breakpoint.sm`
    text-align: left;
    ${props =>
      props.isLanding &&
      `
      padding-bottom: ${props => `${props.theme.spacing(16)}px`};
    `};
    padding-left: ${props => `${props.theme.spacing(5)}px`};
    padding-right: ${props => `${props.theme.spacing(5)}px`};
  `}

  ${breakpoint.md`
    width: ${props => (props.fullWidth ? 100 : 40)}%;
    ${props =>
      props.isLanding
        ? `
      padding-bottom: ${props =>
        props.theme.spacing(props.fullWidth ? 10 : 25)}px;
    `
        : `
      padding-bottom: ${props => props.theme.spacing(11)}px;
    `}
  `}

  ${breakpoint.lg`
    padding: ${props => `${props.theme.spacing(15)}px`} ${props =>
    props.theme.spacing(12)}px;
    width: ${props => (props.fullWidth ? '100%' : '640px')};
  `}

`;

export const PartnersContentArea = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 2;
  background: ${props => alpha(props.theme.palette.common.black, 0.5)};

  padding: ${props => props.theme.spacing(3)}px;

  ${breakpoint.md`
    z-index: 0;
  `}

  & > div {
    width: 100%;
    justify-content: space-evenly;

    ${breakpoint.md`
      margin-left: 45%;
      width: fit-content;
    `}
    ${breakpoint.lg`
      margin-left: 52%;
    `}
  }
`;

export const Heading = styled(UnstyledHeading).attrs({
  size: 'h1',
  gutterBottom: false,
})`
  font-family: ${props => props.theme.fonts.montserrat};
  margin: 72px 0 0;
  color: ${props => props.theme.palette.common.white};
  font-size: 3rem;
  font-weight: 300;
  text-rendering: optimizeLegibility;
`;

export const Hero = styled.div`
  position: relative;
  height: 360px;
  background-image: url(${heroMobile});
  background-size: cover;
  background-position: center center;

  ${breakpoint.md`
    background-image: url(${hero});
    background-position: center center;
  `}

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${props => alpha(props.theme.palette.secondary.main, 0.3)};
  }
`;

export const Grid = styled(UnstyledGrid)`
  position: relative;
  z-index: 2;
  height: 100%;
`;
