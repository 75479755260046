import { httpClient, Response } from '~/services/http';
import { snakecase } from '~/common/casing';
import routes from '../routes';
import {
  WellReport,
  WellReportResponseData,
  BonusQuestionsReport,
} from '~/context/global/reports/types';

export type WellReportRequestBody = WellReport;

export const submitWellReport = (
  wellReport: WellReportRequestBody[],
  recaptchaToken: string
): Promise<Response<WellReportResponseData>> => {
  const wellReportSnakeCase = wellReport.map(report => snakecase(report));
  return httpClient.post<WellReportResponseData>(
    routes.wellReport,
    wellReportSnakeCase,
    {
      headers: { recaptchaToken },
    }
  );
};

export type WellReportBonusQuestionsRequestBody = BonusQuestionsReport;

export const submitWellReportBonusQuestions = (
  bonusQuestions: WellReportBonusQuestionsRequestBody,
  recaptchaToken: string
): Promise<Response<undefined>> => {
  return httpClient.post<undefined>(
    routes.wellReportBonusQuestions,
    bonusQuestions,
    { headers: { recaptchaToken } }
  );
};
