import { httpClient, Response } from '~/services/http';
import routes from '../routes';

interface SymptomsGeoJsonFeature {
  type: 'Feature';
  properties: {
    symptoms: string;
    noSymptoms: string;
    fluSymptoms: string;
    otherSymptoms: string;
    zipcode: string;
  };
  geometry: {
    type: 'Point';
    coordinates: [number, number];
  };
}

export interface GetUserReportedSymptomsMarkersResponse {
  type: 'FeatureCollection';
  features: SymptomsGeoJsonFeature[];
}

export const getUserReportedSymptomsMarkers = (
  countryCode: string
): Promise<Response<GetUserReportedSymptomsMarkersResponse>> => {
  const endpointUrl = `${routes.symptomsMarkers}/${countryCode}`;
  return httpClient.get(endpointUrl);
};
