/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { CountryContext } from '~/context/i18n';
import { useGlobalState, useGlobalDispatch } from '~/context/global';

interface UseSubmissionStatsData {
  submissionCountLocal: number;
  isLoading: boolean;
  isError: boolean;
}
export const useSubmissionStatsData = (): UseSubmissionStatsData => {
  const { country } = useContext(CountryContext);
  const [submissionCountLocal, setSubmissionCountLocal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { searchZipCode, statsByCountry } = useGlobalState();
  const gDispatchObj = useGlobalDispatch();

  const countryCode = country.code.toUpperCase();

  // aggregate NOA submission count
  useEffect(() => {
    const signal = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const url = `/usersubmission/stats/region/NOA`;
        const { data } = await axios.get(url, { cancelToken: signal.token });
        // Save into global state
        gDispatchObj({
          type: 'SET_AGGREGATE_SUBMISSIONS',
          payload: {
            newAggregateSubmissions: data.usersubmissioncountforregion || 0,
          },
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return function cleanup() {
      signal.cancel('Api is being canceled');
    };
  }, []);

  useEffect(() => {
    if (searchZipCode) {
      return;
    }

    const signal = axios.CancelToken.source();

    const { submissionCount } = statsByCountry[countryCode] || {};

    const loadDataFromStore = () => {
      setSubmissionCountLocal(submissionCount);
    };

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const url = `/usersubmission/stats/${countryCode}`;
        const { data } = await axios.get(url, { cancelToken: signal.token });
        setSubmissionCountLocal(data.usersubmissioncount);

        // Save into global state
        gDispatchObj({
          type: 'SET_USER_SUBMISSION_STATS',
          payload: {
            country: countryCode,
            submissionCount: data.usersubmissioncount,
          },
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (submissionCount) {
      loadDataFromStore();
    } else {
      fetchData();
    }

    return function cleanup() {
      signal.cancel('Api is being canceled');
    };
  }, [countryCode, searchZipCode]);

  useEffect(() => {
    const signal = axios.CancelToken.source();

    const { submissionCount } =
      (statsByCountry[countryCode] &&
        statsByCountry[countryCode].zipCodes[searchZipCode]) ||
      {};

    const loadDataFromStore = () => {
      setSubmissionCountLocal(submissionCount);
    };

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const url = `/usersubmission/stats/${countryCode}/${searchZipCode}`;
        const { data } = await axios.get(url, { cancelToken: signal.token });
        setSubmissionCountLocal(data.usersubmissioncount);

        // Save into global state
        gDispatchObj({
          type: 'SET_USER_SUBMISSION_STATS_FOR_ZIP',
          payload: {
            country: countryCode,
            zipCode: searchZipCode,
            submissionCount: data.usersubmissioncount,
          },
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (searchZipCode) {
      if (submissionCount) {
        loadDataFromStore();
      } else {
        fetchData();
      }
    }

    return function cleanup() {
      signal.cancel('Api is being canceled');
    };
  }, [searchZipCode]);

  return { submissionCountLocal, isLoading, isError };
};
