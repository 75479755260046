import CountUp from 'react-countup';
import styled from 'styled-components';
import breakpoint from '~/common/breakpoint';
import { Button, Typography } from '@material-ui/core';
import { Skeleton as UnstyledSkeleton } from '@material-ui/lab';

export const Skeleton = styled(UnstyledSkeleton).attrs(props => ({
  animation: 'wave',
  variant: 'rect',
  height: props.height,
}))`
  margin-bottom: 0.5rem;
`;

export const InfoText = styled(Typography).attrs(props => ({
  variant: props.variant || 'h6',
  component: 'p',
}))`
  font-size: 18px;
  text-align: center;
  font-weight: normal;
  margin-bottom: ${props => props.theme.spacing(5)}px;

  ${breakpoint.md`
    font-size: 26px;
    text-align: left;
  `}
`;

export const Counter = styled(CountUp).attrs({
  className: 'helpTrack-countup',
  separator: ',',
})`
  font-weight: bold;
`;

export const MainQuestion = styled(Typography).attrs({
  variant: 'h3',
  component: 'p',
})`
  font-size: 42px;
  font-weight: 500;
  text-align: center;
  margin-bottom: ${props => props.theme.spacing(8)}px;

  ${breakpoint.md`
    font-size: 60px;
    text-align: left;
  `}
`;

const HealthReportButton = styled(Button).attrs({
  size: 'large',
  fullWidth: true,
  variant: 'contained',
})`
  font-size: 16px;
  text-transform: none;
  color: ${({ theme }) => theme.palette.common.white};

  ${breakpoint.md`
    padding: ${({ theme }) => `${theme.spacing(1.625)}px 0`};
  `}
`;

export const FeelingWellButton = styled(HealthReportButton)`
  background: ${({ theme }) => theme.palette.success.main};
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.palette.success.main};
  }
`;

export const FeelingUnwellButton = styled(HealthReportButton)`
  background: ${({ theme }) => theme.palette.error.main};
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.palette.error.main};
  }
`;

export const HouseholdReportButton = styled(HealthReportButton)`
  background: ${({ theme }) => theme.palette.purple.main};
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.palette.purple.main};
  }
`;
