import { useSelector } from '~/store';
import { useGlobalState } from '~/context/global';
import { HouseholdMember } from '~/context/global/user/account';

interface UseHouseholdMembers {
  activeHouseholdMembers: HouseholdMember[];
  hasActiveHouseholdMembers: () => boolean;
}

export const useHouseholdMembers = (): UseHouseholdMembers => {
  const { account } = useGlobalState().user;
  const { loggedIn } = useSelector(state => state.userAuth);

  const activeHouseholdMembers = account?.householdMembers?.filter(
    member => member.isActive
  );

  const hasActiveHouseholdMembers = () =>
    loggedIn && activeHouseholdMembers?.length > 0;

  return { activeHouseholdMembers, hasActiveHouseholdMembers };
};
