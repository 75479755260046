const isLocalStorageAvailable = () => {
  if (typeof window === 'undefined' || typeof Storage === 'undefined') {
    return false;
  }

  try {
    const testKey = '__testkey__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch {
    return false;
  }
};

export const getStorageItem = item => {
  if (isLocalStorageAvailable()) {
    return JSON.parse(localStorage.getItem(item));
  }
};

export const setStorageItem = (item, value) => {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(item, value);
  }
};

export const removeStorageItem = item => {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(item);
  }
};

const submissionIdsLocalStorageKey = 'submissionIds';

export const storeSubmissionId = submissionId => {
  if (isLocalStorageAvailable()) {
    const submissionIds = getSubmissionIdsFromLocalStorage() || [];
    submissionIds.push(submissionId);
    const submissionIdsString = JSON.stringify(submissionIds);
    setStorageItem(submissionIdsLocalStorageKey, submissionIdsString);
  }
};

export const getSubmissionIdsFromLocalStorage = () => {
  if (isLocalStorageAvailable()) {
    return getStorageItem(submissionIdsLocalStorageKey);
  }
};
